// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DossierTypeListHandlePositionField(
    $navigationSlug: String!,
    $dossierTypeSlug: String!,
    $data: JSON
  ) {
    successful: dossierTypeListHandlePositionField(
      navigationSlug: $navigationSlug
      dossierTypeSlug: $dossierTypeSlug
      data: $data
    )
  }
`;

export default MUTATION;
